.sm-blue {
  background: transparent;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
.sm-blue a, .sm-blue a:hover, .sm-blue a:focus, .sm-blue a:active {
    padding-top: 15px;
    padding-bottom: 15px;
  /*padding: 10px 20px;*/
  /* make room for the toggle button (sub indicator) */
  /*padding-right: 58px;*/
  /*background: #4d4c76;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzRmNGU3OSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzRiNGE3MyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #4f4e79), color-stop(100%, #4b4a73));
  background-image: -moz-linear-gradient(top, #4f4e79, #4b4a73);
  background-image: -webkit-linear-gradient(top, #4f4e79, #4b4a73);
  background-image: linear-gradient(to bottom, #4f4e79, #4b4a73);
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-weight: normal;*/
  line-height: 23px;
  text-decoration: none;
  text-shadow: 0 1px 0 transparent;
}
.sm-blue a.current {
  /*background: #2f2e47;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJkMmM0NCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMxMzA0YiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #2d2c44), color-stop(100%, #31304b));
  background-image: -moz-linear-gradient(top, #2d2c44, #31304b);
  background-image: -webkit-linear-gradient(top, #2d2c44, #31304b);
  background-image: linear-gradient(to bottom, #2d2c44, #31304b);
  color: #fff;*/
}
/*.sm-blue a.disabled {
  color: #9d9cbf;
}*/
.sm-blue a span.sub-arrow {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 4px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 16px/34px monospace !important;
  text-align: center;
  text-shadow: none;
  /*background: rgba(0, 0, 0, 0.1);*/
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.sm-blue a.highlighted span.sub-arrow:before {
  display: block;
  content: '-';
}
.sm-blue > li:first-child > a, .sm-blue > li:first-child > :not(ul) a {
  -moz-border-radius: 0px 0px 0 0;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 0 0;
}
.sm-blue > li:last-child > a, .sm-blue > li:last-child > *:not(ul) a, .sm-blue > li:last-child > ul, .sm-blue > li:last-child > ul > li:last-child > a, .sm-blue > li:last-child > ul > li:last-child > *:not(ul) a, .sm-blue > li:last-child > ul > li:last-child > ul, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > a, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul {
  -moz-border-radius: 0 0 0px 0px;
  -webkit-border-radius: 0;
  border-radius: 0 0 0px 0px;
}
.sm-blue > li:last-child > a.highlighted, .sm-blue > li:last-child > *:not(ul) a.highlighted, .sm-blue > li:last-child > ul > li:last-child > a.highlighted, .sm-blue > li:last-child > ul > li:last-child > *:not(ul) a.highlighted, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted, .sm-blue > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.sm-blue ul {
  background: #fff;
}
.sm-blue ul ul {
  background: rgba(102, 102, 102, 0.1);
}
.sm-blue ul a, .sm-blue ul a:hover, .sm-blue ul a:focus, .sm-blue ul a:active {
  background: transparent;
  /*color: #353451;*/
    color: #000; /**/
  /*font-size: 16px;*/
  text-shadow: none;
  border-left: 8px solid transparent;
}
.sm-blue ul a.current {
  /*background: #2f2e47;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJkMmM0NCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMxMzA0YiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #2d2c44), color-stop(100%, #31304b));
  background-image: -moz-linear-gradient(top, #2d2c44, #31304b);
  background-image: -webkit-linear-gradient(top, #2d2c44, #31304b);
  background-image: linear-gradient(to bottom, #2d2c44, #31304b);
  color: #fff;*/
}
/*.sm-blue ul a.disabled {
  color: #b3b3b3;
}*/
.sm-blue ul ul a,
.sm-blue ul ul a:hover,
.sm-blue ul ul a:focus,
.sm-blue ul ul a:active {
  border-left: 16px solid transparent;
}
.sm-blue ul ul ul a,
.sm-blue ul ul ul a:hover,
.sm-blue ul ul ul a:focus,
.sm-blue ul ul ul a:active {
  border-left: 24px solid transparent;
}
.sm-blue ul ul ul ul a,
.sm-blue ul ul ul ul a:hover,
.sm-blue ul ul ul ul a:focus,
.sm-blue ul ul ul ul a:active {
  border-left: 32px solid transparent;
}
.sm-blue ul ul ul ul ul a,
.sm-blue ul ul ul ul ul a:hover,
.sm-blue ul ul ul ul ul a:focus,
.sm-blue ul ul ul ul ul a:active {
  border-left: 40px solid transparent;
}
.sm-blue ul li {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.sm-blue ul li:first-child {
  border-top: 0;
}

@media (min-width: 768px) {
  /* Switch to desktop layout
  -----------------------------------------------
     These transform the menu tree from
     collapsible to desktop (navbar + dropdowns)
  -----------------------------------------------*/
  /* start... (it's not recommended editing these rules) */
  .sm-blue ul {
    position: absolute;
    width: 12em;
  }

  .sm-blue li {
    float: left;
  }


  .sm-blue.sm-rtl li {
    float: right;
  }

  .sm-blue ul li, .sm-blue.sm-rtl ul li, .sm-blue.sm-vertical li {
    float: none;
  }

  .sm-blue a {
   /* white-space: nowrap;*/
  }

  .sm-blue ul a, .sm-blue.sm-vertical a {
    white-space: normal;
  }

  .sm-blue .sm-nowrap > li > a, .sm-blue .sm-nowrap > li > :not(ul) a {
   /* white-space: nowrap;*/
  }

  /* ...end */
  .sm-blue {
    /*background: #4d4c76;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzRmNGU3OSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzRiNGE3MyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #4f4e79), color-stop(100%, #4b4a73));
    background-image: -moz-linear-gradient(top, #4f4e79, #4b4a73);
    background-image: -webkit-linear-gradient(top, #4f4e79, #4b4a73);
    background-image: linear-gradient(to bottom, #4f4e79, #4b4a73);
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);*/
  }
  .sm-blue a, .sm-blue a:hover, .sm-blue a:focus, .sm-blue a:active, .sm-blue a.highlighted {
    /*padding: 14px 23px;*/
    /*background: #4d4c76;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzRmNGU3OSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzRiNGE3MyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #4f4e79), color-stop(100%, #4b4a73));
    background-image: -moz-linear-gradient(top, #4f4e79, #4b4a73);
    background-image: -webkit-linear-gradient(top, #4f4e79, #4b4a73);
    background-image: linear-gradient(to bottom, #4f4e79, #4b4a73);
    color: #fff;*/
  }
  .sm-blue a:hover, .sm-blue a:focus, .sm-blue a:active, .sm-blue a.highlighted {
    /*background: #434266;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzQ1NDQ2YSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzQxNDA2MyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #45446a), color-stop(100%, #414063));
    background-image: -moz-linear-gradient(top, #45446a, #414063);
    background-image: -webkit-linear-gradient(top, #45446a, #414063);
    background-image: linear-gradient(to bottom, #45446a, #414063);*/
  }
  .sm-blue a.current {
    /*background: #2f2e47;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJkMmM0NCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMxMzA0YiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #2d2c44), color-stop(100%, #31304b));
    background-image: -moz-linear-gradient(top, #2d2c44, #31304b);
    background-image: -webkit-linear-gradient(top, #2d2c44, #31304b);
    background-image: linear-gradient(to bottom, #2d2c44, #31304b);
    color: #fff;*/
  }
  .sm-blue a.disabled {
    /*background: #4d4c76;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzRmNGU3OSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzRiNGE3MyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #4f4e79), color-stop(100%, #4b4a73));
    background-image: -moz-linear-gradient(top, #4f4e79, #4b4a73);
    background-image: -webkit-linear-gradient(top, #4f4e79, #4b4a73);
    background-image: linear-gradient(to bottom, #4f4e79, #4b4a73);
    color: #9d9cbf;*/
  }
  .sm-blue a span.sub-arrow {
    top: auto;
    margin-top: 0;
    bottom: 2px;
    left: 50%;
    margin-left: -5px;
    right: auto;
    width: 0;
    height: 0;
    border-width: 5px;
    border-style: solid dashed dashed dashed;
    border-color: #dadae7 transparent transparent transparent;
    background: transparent;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  .sm-blue a.highlighted span.sub-arrow:before {
    display: none;
  }
  .sm-blue > li:first-child > a, .sm-blue > li:first-child > :not(ul) a {
    -moz-border-radius: 0px 0 0 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px 0 0 0px;
  }
  .sm-blue > li:last-child > a, .sm-blue > li:last-child > :not(ul) a {
    -moz-border-radius: 0 0px 0px 0 !important;
    -webkit-border-radius: 0;
    border-radius: 0 0px 0px 0 !important;
  }
  .sm-blue > li {
    border-left: 0px solid #434266;
  }
  .sm-blue > li:first-child {
    border-left: 0;
  }
  .sm-blue ul {
    border: 0px solid #a8a8a8;
    padding: 5px 0;
    background: #fff;
    -moz-border-radius: 0 0 0px 0px !important;
    -webkit-border-radius: 0;
    border-radius: 0 0 0px 0px !important;
    -moz-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3);
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.3);
  }
  .sm-blue ul ul {
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px;
    border-radius: 0px !important;
    background: #fff;
  }
  .sm-blue ul a, .sm-blue ul a:hover, .sm-blue ul a:focus, .sm-blue ul a:active, .sm-blue ul a.highlighted {
    border: 0 !important;
    padding: 10px 23px;
    background: transparent;
    /*color: #353451;*/
      color: #999; /**/
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0;
    border-radius: 0 !important;
  }
  .sm-blue ul a:hover, .sm-blue ul a:focus, .sm-blue ul a:active, .sm-blue ul a.highlighted {
    /*background: #4d4c76;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzRmNGU3OSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzRiNGE3MyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #4f4e79), color-stop(100%, #4b4a73));
    background-image: -moz-linear-gradient(top, #4f4e79, #4b4a73);
    background-image: -webkit-linear-gradient(top, #4f4e79, #4b4a73);
    background-image: linear-gradient(to bottom, #4f4e79, #4b4a73);
    color: #fff;*/
  }
  .sm-blue ul a.current {
    /*background: #2f2e47;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzJkMmM0NCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMxMzA0YiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #2d2c44), color-stop(100%, #31304b));
    background-image: -moz-linear-gradient(top, #2d2c44, #31304b);
    background-image: -webkit-linear-gradient(top, #2d2c44, #31304b);
    background-image: linear-gradient(to bottom, #2d2c44, #31304b);
    color: #fff;*/
  }
  /*.sm-blue ul a.disabled {
    background: #fff;
    color: #b3b3b3;
  }*/
  .sm-blue ul a span.sub-arrow {
    top: 50%;
    margin-top: -5px;
    bottom: auto;
    left: auto;
    margin-left: 0;
    right: 10px;
    border-style: dashed dashed dashed solid;
    border-color: transparent transparent transparent #dadae7;
  }
  .sm-blue ul li {
    border: 0;
  }
  .sm-blue span.scroll-up,
  .sm-blue span.scroll-down {
    position: absolute;
    display: none;
    visibility: hidden;
    overflow: hidden;
    background: #fff;
    height: 20px;
  }
  .sm-blue span.scroll-up-arrow, .sm-blue span.scroll-down-arrow {
    position: absolute;
    top: -2px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-width: 8px;
    border-style: dashed dashed solid dashed;
    border-color: transparent transparent #353451 transparent;
  }
  .sm-blue span.scroll-down-arrow {
    top: 6px;
    border-style: solid dashed dashed dashed;
    border-color: #353451 transparent transparent transparent;
  }
  .sm-blue.sm-rtl.sm-vertical a span.sub-arrow {
    right: auto;
    left: 10px;
    border-style: dashed solid dashed dashed;
    border-color: transparent #dadae7 transparent transparent;
  }
  .sm-blue.sm-rtl > li:first-child > a, .sm-blue.sm-rtl > li:first-child > :not(ul) a {
    -moz-border-radius: 0 0px 0px 0;
    -webkit-border-radius: 0;
    border-radius: 0 0px 0px 0;
  }
  .sm-blue.sm-rtl > li:last-child > a, .sm-blue.sm-rtl > li:last-child > :not(ul) a {
    -moz-border-radius: 0px 0 0 0px !important;
    -webkit-border-radius: 0px;
    border-radius: 0px 0 0 0px !important;
  }
  .sm-blue.sm-rtl > li:first-child {
    border-left: 0px solid #434266;
  }
  .sm-blue.sm-rtl > li:last-child {
    border-left: 0;
  }
  .sm-blue.sm-rtl ul a span.sub-arrow {
    right: auto;
    left: 10px;
    border-style: dashed solid dashed dashed;
    border-color: transparent #dadae7 transparent transparent;
  }
  .sm-blue.sm-vertical {
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  }
  .sm-blue.sm-vertical a {
    padding: 9px 23px;
  }
  .sm-blue.sm-vertical a span.sub-arrow {
    top: 50%;
    margin-top: -5px;
    bottom: auto;
    left: auto;
    margin-left: 0;
    right: 10px;
    border-style: dashed dashed dashed solid;
    border-color: transparent transparent transparent #dadae7;
  }
  .sm-blue.sm-vertical > li:first-child > a, .sm-blue.sm-vertical > li:first-child > :not(ul) a {
    -moz-border-radius: 0px 0px 0 0;
    -webkit-border-radius: 0px;
    border-radius: 0px 0px 0 0;
  }
  .sm-blue.sm-vertical > li:last-child > a, .sm-blue.sm-vertical > li:last-child > :not(ul) a {
    -moz-border-radius: 0 0 0px 0px !important;
    -webkit-border-radius: 0;
    border-radius: 0 0 0px 0px !important;
  }
  .sm-blue.sm-vertical > li {
    border-left: 0 !important;
  }
  .sm-blue.sm-vertical ul {
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px;
    border-radius: 0px !important;
  }
  .sm-blue.sm-vertical ul a {
    padding: 10px 23px;
  }
}