.main-nav {
  position: relative;
  /*background: #3092c0;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #4f4e79), color-stop(100%, #4b4a73));
  background-image: -webkit-linear-gradient(top, #4f4e79, #4b4a73);
  background-image: -moz-linear-gradient(to bottom, #4f4e79, #4b4a73);
  background-image: -o-linear-gradient(to bottom, #4f4e79, #4b4a73);
  background-image: linear-gradient(to bottom, #4f4e79, #4b4a73);
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);*/
}

.main-nav:after {
  clear: both;
  content: "\00a0";
  display: block;
  height: 0;
  font: 0px/0 serif;
  overflow: hidden;
}

/*.nav-brand {
  float: left;
  margin: 0;
}

.nav-brand a {
  display: block;
  padding: 10px 10px 10px 20px;
  color: #fff;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-weight: normal;
  line-height: 29px;
  text-decoration: none;
}*/

#main-menu {
  clear: both;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

@media (min-width: 768px) {
  #main-menu {
    float: right;
    clear: none;
  }
}


/* Mega menus */


/* make mega menus take the full width of .main-nav */

#main-menu,
#main-menu > .has-mega-menu {
  position: static !important;
}

#main-menu .mega-menu {
  margin-left: 0 !important;
  right: 0 !important;
  width: auto !important;
  max-width: none !important;
}

#main-menu > .has-mega-menu .scroll-up,
#main-menu > .has-mega-menu .scroll-down {
  margin-left: 0 !important;
}


/* clear any floats inside the mega menus */

#main-menu .mega-menu > li {
  overflow: auto;
}


/* reset mega menu list/link styles (which are inherited from the SmartMenus core/theme styles) */

#main-menu .mega-menu {
  /*color: #353451;*/
    color: #000; /**/
}

#main-menu .mega-menu ul {
  position: static;
  display: block;
  margin: 0.83em 0;
  border: 0;
  padding: 0 0 0 20px;
  width: auto;
  background: transparent;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#main-menu .mega-menu ul li {
  display: list-item;
  list-style-type: disc;
  border: 0;
}

#main-menu .mega-menu a {
  display: inline;
  border: 0;
  padding: 0;
  background: transparent;
  color: inherit;
  /*text-decoration: underline;*/
}


/* simple columns inside the mega menus */

#main-menu .mega-menu .column-1-3 {
  padding: 0 20px;
}

@media (min-width: 768px) {
  #main-menu .mega-menu .column-1-3 {
    float: left;
    width: 33.33%;
  }
}


/* Sample image placeholder */

.mega-menu-sample-image {
  margin: 0.83em 0;
  border: 1px solid;
  height: 80px;
  line-height: 80px;
  text-align: center;
}


/* Mobile menu top separator */

#main-menu:before {
  content: '';
  display: block;
  height: 1px;
  font: 1px/1px sans-serif;
  overflow: hidden;
  background: #4b4a73;
}

@media (min-width: 768px) {
  #main-menu:before {
    display: none;
  }
}


/* Mobile menu toggle button */

.main-menu-btn {
  float: right;
  margin: 10px;
  position: relative;
  display: inline-block;
  width: 29px;
  height: 29px;
  text-indent: 29px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


/* hamburger icon */

.main-menu-btn-icon,
.main-menu-btn-icon:before,
.main-menu-btn-icon:after {
  position: absolute;
  top: 50%;
  left: 2px;
  height: 2px;
  width: 24px;
    background: #fff;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.main-menu-btn-icon:before {
  content: '';
  top: -7px;
  left: 0;
}

.main-menu-btn-icon:after {
  content: '';
  top: 7px;
  left: 0;
}


/* x icon */

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
  height: 0;
  background: transparent;
}

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
  top: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
  top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}


/* hide menu state checkbox (keep it visible to screen readers) */

#main-menu-state {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}


/* hide the menu in mobile view */

#main-menu-state:not(:checked) ~ #main-menu {
  display: none;
}

#main-menu-state:checked ~ #main-menu {
  display: block;
}

@media (min-width: 768px) {
  /* hide the button in desktop view */
  .main-menu-btn {
    position: absolute;
    top: -99999px;
  }
  /* always show the menu in desktop view */
  #main-menu-state:not(:checked) ~ #main-menu {
    display: block;
  }
}